import "./dashboard.scss";
import NavbarVendas from "../../components/navbar/navbarVendas";
import { Avatar, DatePicker, Divider, message } from "antd";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { DrawerContext } from "../../contexts/drawerContext";
import { UserContext } from "../../contexts/userContext";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { StyledTabs } from "../../components/custom-ui";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { axiosInstance } from "../../services/axios";
import {
  toDate,
  format,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import dayjs from "dayjs";
import { FilterByDateTab, ActivitiesTab } from "../../pages/auditing/auditing";

const { RangePicker } = DatePicker;

const months = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Out",
  "Nov",
  "Dec",
];

const options = {
  chart: {
    type: "column",
  },
  subtitle: {
    text: "Fonte: ChatWiz",
    align: "left",
  },
  series: [],
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  legend: {
    enabled: false,
  },
};

const Tab1 = ({ chartOptions }) => {
  return (
    <>
      <Divider />
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

const Tab2 = ({ chartOptions }) => {
  return (
    <>
      <Divider />
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

const defaultFetchOrdersParamsStrategy = {
  month: {
    granularity: "month",
    startDate: format(startOfYear(new Date()), "MM-dd-yyyy"),
    endDate: format(endOfYear(new Date()), "MM-dd-yyyy"),
  },
  week: {
    granularity: "week",
    startDate: format(startOfMonth(new Date()), "MM-dd-yyyy"),
    endDate: format(endOfMonth(new Date()), "MM-dd-yyyy"),
  },
};

const adjustRangePickerStrategy = {
  month: {
    start: (startDate) => format(startOfMonth(startDate), "MM-dd-yyyy"),
    end: (endDate) => format(endOfMonth(endDate), "MM-dd-yyyy"),
  },
  week: {
    start: (startDate) => format(startOfWeek(startDate), "MM-dd-yyyy"),
    end: (endDate) => format(endOfWeek(endDate), "MM-dd-yyyy"),
  },
};

const DashboardPage = () => {
  const { isVisible } = useContext(DrawerContext);
  const [ordersCount, setOrdersCount] = useState({
    orderRelease: 0,
    purcharseOrder: 0,
  });
  const [currentTab, setCurrentTab] = useState(2);
  const [chartOptions, setChartOptions] = useState({ ...options });
  const [graphGranularity, setGraphGranularity] = useState("month");
  const [auditingData, setAuditingData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [rangePickerValue, setRangePickerValue] = useState([
    dayjs(defaultFetchOrdersParamsStrategy[graphGranularity].startDate),
    dayjs(defaultFetchOrdersParamsStrategy[graphGranularity].endDate),
  ]);

  const { user } = useContext(UserContext);

  const activitiesTabs = [
    {
      label: "Dia",
      children: <FilterByDateTab period="day" auditingData={auditingData} />,
      key: "day",
    },
    {
      label: "Semana",
      children: <FilterByDateTab period="week" auditingData={auditingData} />,
      key: "week",
    },
    {
      label: "Mês",
      children: <FilterByDateTab period="month" auditingData={auditingData} />,
      key: "month",
    },
  ];

  const fetchTotalOrders = async () => {
    const { data: ordersCount } = await axiosInstance.get(
      `/dashboard/countTotalOrders`
    );
    setOrdersCount(ordersCount);
  };

  const fetchOrdersRelease = async () => {
    const { data: ordersRelease } = await axiosInstance.get(
      `/order-release/count-by-date?granularity=month&start=01-01-2024&end=12-31-2024`
    );
  };

  const sanitiZeChartData = (orders, granularity) => {
    const granularityCategoryStrategy = {
      month: (value) => {
        const date = toDate(`${value}-01T00:00:00`);
        return months[date.getMonth()];
      },
      week: (value) => {
        const [year, week] = value.split("-W");
        return `Semana ${week} de ${year}`;
      },
    };

    return new Promise((resolve, reject) => {
      const xAxis = {
        categories: [],
      };
      const data = [];
      for (let order of orders) {
        data.push(order.count);
        xAxis.categories.push(
          granularityCategoryStrategy[granularity](order.time)
        );
      }
      resolve({ data, xAxis });
    });
  };

  const fetchOrdersPurcharsed = async (
    granularity = defaultFetchOrdersParamsStrategy[graphGranularity]
      .granularity,
    startDate = defaultFetchOrdersParamsStrategy[graphGranularity].startDate,
    endDate = defaultFetchOrdersParamsStrategy[graphGranularity].endDate
  ) => {
    const { data: purcharseOrders } = await axiosInstance.get(
      `/purchase-order/count-by-date?granularity=${granularity}&start=${startDate}&end=${endDate}`
    );

    const { xAxis, data } = await sanitiZeChartData(
      purcharseOrders,
      granularity
    );

    setChartOptions({
      ...chartOptions,
      title: {
        text: "Histórico de ordens de compra",
        align: "left",
      },
      series: {
        name: "Compras",
        type: "column",
        color: "rgba(56, 158, 13, 1)",
        data,
      },
      xAxis,
    });
  };

  useEffect(() => {
    fetchTotalOrders();
    getAuditingData("day");
  }, []);

  useEffect(() => {
    if (currentTab === 1) {
      fetchOrdersRelease();
    } else {
      changeRangePickerValue(rangePickerValue[0].$d, rangePickerValue[1].$d);
    }
  }, [currentTab, graphGranularity]);

  const changeRangePickerValue = (start, end) => {
    const startDate = adjustRangePickerStrategy[graphGranularity].start(start);
    const endDate = adjustRangePickerStrategy[graphGranularity].end(end);
    setRangePickerValue([dayjs(startDate), dayjs(endDate)]);
    fetchOrdersPurcharsed(graphGranularity, startDate, endDate);
  };

  const handleRangePickerChange = (e) => {
    changeRangePickerValue(e[0].$d, e[1].$d);
  };

  const getAuditingData = async (period) => {
    try {
      const response = await axiosInstance(
        `/auditing?page=1&pageSize=20&filterBy=${period}`
      );

      setAuditingData(response.data.data);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao buscar ordens de compra",
      });
    } finally {
    }
  };

  return (
    <>
      {contextHolder}
      <div className="dashboard" style={{ marginLeft: isVisible ? 272 : 0 }}>
        <NavbarVendas />
        <div
          className="dashboard__body"
          style={{ paddingLeft: isVisible ? 0 : 16 }}
        >
          <div className="dashboard__body__card-table">
            <h3 className="dashboard__body__card-table__title">Dashboard</h3>
            <div className="dashboard__body__card-table__header">
              <div className="dashboard__body__card-table__header__greetings">
                {/* <Avatar
                  src={
                    <img
                      src={DefaultAvatar}
                      alt="avatar"
                      style={{ width: 72, height: 72 }}
                    />
                  }
                  style={{ marginRight: 12 }}
                /> */}
                <div>
                  <p style={{ marginBottom: 10 }}>Bom dia, {user.name}.</p>
                  <p>Tenha um ótimo dia!</p>
                </div>
              </div>
              <div
                className="dashboard__body__card-table__header__metrics"
                style={{ marginRight: 34 }}
              >
                <p>Ordens de compra</p>
                <p className="dashboard__body__card-table__header__metrics__value">
                  {ordersCount.purcharseOrder}
                </p>
              </div>
              <div className="dashboard__body__card-table__header__metrics">
                <p>Pedidos Liberados</p>
                <p className="dashboard__body__card-table__header__metrics__value">
                  {ordersCount.orderRelease}
                </p>
              </div>
            </div>
            <div className="dashboard__body__card-graph">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: -35,
                }}
              >
                <div style={{ display: "", marginLeft: "auto" }}>
                  {/* <span
                  className="dashboard__body__card-graph__granularity"
                >
                  Dia
                </span>
                 */}
                  <span
                    className={`dashboard__body__card-graph__granularity ${
                      graphGranularity === "week"
                        ? "dashboard__body__card-graph__granularity--selected"
                        : ""
                    }`}
                    onClick={() => setGraphGranularity("week")}
                  >
                    Semana
                  </span>
                  <span
                    className={`dashboard__body__card-graph__granularity ${
                      graphGranularity === "month"
                        ? "dashboard__body__card-graph__granularity--selected"
                        : ""
                    }`}
                    onClick={() => setGraphGranularity("month")}
                  >
                    Mês
                  </span>
                  {/* <span className="dashboard__body__card-graph__granularity">
                  Ano
                </span> */}
                </div>
                <div style={{ marginLeft: 24, zIndex: 100 }}>
                  <RangePicker
                    onChange={handleRangePickerChange}
                    value={rangePickerValue}
                  />
                </div>
              </div>
              <StyledTabs
                items={[
                  // {
                  //   label: "Pedidos liberados",
                  //   children: <Tab1 chartOptions={chartOptions} />,
                  //   key: 1,
                  // },
                  {
                    label: "Ordens de compra",
                    children: <Tab2 chartOptions={chartOptions} />,
                    key: 2,
                  },
                ]}
                style={{ width: "100%" }}
                onTabClick={(tabKey) => setCurrentTab(tabKey)}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <ActivitiesTab
            subtabs={activitiesTabs}
            onChangeFilterDayTabs={getAuditingData}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
