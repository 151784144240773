import "./packing.scss";
import NavbarVendas from "../../components/navbar/navbarVendas";
import { Divider, Spin, message } from "antd";
import { useState, useEffect } from "react";
import { StyledTabs, StyledButtonPrimary } from "../../components/custom-ui";
import { axiosInstance } from "../../services/axios";
import { useContext } from "react";
import { DrawerContext } from "../../contexts/drawerContext";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SalesForm, LogisticForm, WareHouseForm } from "./forms";
import PackingResume from "./packing-resume/packing-ressume";
import CreateSealModal from "./packing-resume/create-seal-modal";
import ReleaseOrderModal from "./packing-resume/release-order-modal";
import { UserContext } from "../../contexts/userContext";
import BoardInstructionsModal from "./packing-resume/board-instructions-modal";

const OrderForm = ({ packing, order, user_role, fetchOrder }) => {
  const isFormDisabled = (permittedRole) => {
    return user_role !== permittedRole && user_role !== "admin";
  };

  const forms = [
    {
      role: "sales",
      element: (
        <SalesForm
          disabled={isFormDisabled("sales") || packing.approved}
          order={order}
          packingId={packing.id}
          fetchOrder={fetchOrder}
        />
      ),
    },
    {
      role: "logistic",
      element: (
        <LogisticForm
          disabled={isFormDisabled("logistic") || packing.approved}
          order={order}
          packingId={packing.id}
          fetchOrder={fetchOrder}
        />
      ),
    },
    {
      role: "warehouse",
      element: (
        <WareHouseForm
          disabled={isFormDisabled("warehouse") || packing.approved}
          order={order}
          packingId={packing.id}
          fetchOrder={fetchOrder}
        />
      ),
    },
  ];

  forms.sort((a, b) => {
    if (a.role === user_role) return -1;

    return 1;
  });

  return (
    <>
      {forms.map((form) => {
        return (
          <div key={form.role}>
            {form.element}
            <Divider />
          </div>
        );
      })}
    </>
  );
};

const PackingPage = () => {
  const { user } = useContext(UserContext);

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [packing, setPacking] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [isApprovePackginButtonDisabled, setIsApprovePackginButtonDisabled] =
    useState(false);
  const [showResumePacking, setShowResumePacking] = useState(true);
  const [showCreateSealModal, setShowCreateSealModal] = useState(false);
  const [showReleaseOrderModal, setShowReleaseOrderModal] = useState(false);
  const [showBoardingInstructionsModal, setShowBoardingInstructionsModal] =
    useState(false);

  const navigate = useNavigate();

  const { packingId } = useParams();

  const { isVisible } = useContext(DrawerContext);

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/packing/${packingId}`);
      setPacking(response.data);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao buscar packing",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isNotNullAndUndefined = (value) => {
    return value !== undefined && value !== null;
  };

  useEffect(() => {
    if (packing) {
      let allPurchasesCompleted = false;
      const tabs = packing.purchase_orders.map((order) => {
        allPurchasesCompleted = false;
        if (
          // Sales Form
          isNotNullAndUndefined(order.pn) &&
          isNotNullAndUndefined(order.family) &&
          isNotNullAndUndefined(order.plate_quantity) &&
          isNotNullAndUndefined(order.service_time) &&
          // Logistic form
          isNotNullAndUndefined(order.reference) &&
          isNotNullAndUndefined(order.cubed_weight) &&
          isNotNullAndUndefined(order.dimensional_weight) &&
          isNotNullAndUndefined(order.shipping_company) &&
          // Warehouse form
          isNotNullAndUndefined(order.box_dimension_x) &&
          isNotNullAndUndefined(order.box_dimension_y) &&
          isNotNullAndUndefined(order.box_dimension_z) &&
          isNotNullAndUndefined(order.pallet_dimension_x) &&
          isNotNullAndUndefined(order.pallet_dimension_y) &&
          isNotNullAndUndefined(order.pallet_dimension_z) &&
          isNotNullAndUndefined(order.gross_weight) &&
          isNotNullAndUndefined(order.box_quantity) &&
          isNotNullAndUndefined(order.pallet_quantity) &&
          isNotNullAndUndefined(order.pn_box)
        ) {
          allPurchasesCompleted = true;
        }

        return {
          label: `PN ${order.pn}`,
          children: (
            <OrderForm
              order={order}
              user_role={user.role}
              packing={packing}
              fetchOrder={fetchOrder}
            />
          ),
          key: order.id,
        };
      });
      setIsApprovePackginButtonDisabled(!allPurchasesCompleted);
      setShowResumePacking(allPurchasesCompleted);
      setTabs(tabs);
    }
  }, [packing, user]);

  return (
    <>
      <div className="packing" style={{ marginLeft: isVisible ? 272 : 0 }}>
        <NavbarVendas />
        {contextHolder}
        <div
          className="packing__body"
          style={{ paddingLeft: isVisible ? 0 : 16 }}
        >
          <Spin spinning={isLoading}>
            <div className="packing__body__card-table">
              <h3 className="packing__body__card-table__title">
                <ArrowLeftOutlined
                  onClick={() => navigate(-1)}
                  style={{ marginRight: 24, cursor: "pointer" }}
                />
                Dados de Packing - {packing?.instance}
              </h3>
              {packing?.seal && (
                <div className="packing__seal">LACRE {packing?.seal}</div>
              )}
              <div style={{ display: "flex", marginTop: 30 }}>
                {user.role === "warehouse" && (
                  <div style={{ marginLeft: "auto" }}>
                    {showResumePacking && !packing?.approved ? (
                      <>
                        <StyledButtonPrimary
                          style={{ width: 200 }}
                          onClick={() => setShowResumePacking(false)}
                        >
                          Editar Packing
                        </StyledButtonPrimary>
                      </>
                    ) : (
                      !packing?.approved && (
                        <>
                          <StyledButtonPrimary
                            style={{ width: 200 }}
                            onClick={() => setShowResumePacking(true)}
                          >
                            Visualizar resumo
                          </StyledButtonPrimary>
                        </>
                      )
                    )}
                    {!packing?.approved && (
                      <StyledButtonPrimary
                        type="primary"
                        style={{ width: 200, marginLeft: "10px" }}
                        disabled={isApprovePackginButtonDisabled}
                        onClick={() => setShowCreateSealModal(true)}
                      >
                        Aprovar Packing
                      </StyledButtonPrimary>
                    )}
                  </div>
                )}
                {user.role === "sales" && (
                  <div style={{ marginLeft: "auto" }}>
                    {showResumePacking ? (
                      <StyledButtonPrimary
                        style={{ width: 200 }}
                        onClick={() => setShowResumePacking(false)}
                      >
                        Editar Packing
                      </StyledButtonPrimary>
                    ) : (
                      <>
                        <StyledButtonPrimary
                          style={{ width: 200 }}
                          onClick={() => setShowResumePacking(true)}
                        >
                          Visualizar resumo
                        </StyledButtonPrimary>
                      </>
                    )}
                    {!packing?.order_release_started && (
                      <StyledButtonPrimary
                        type="primary"
                        style={{ width: 200, marginLeft: "10px" }}
                        disabled={
                          isApprovePackginButtonDisabled || !packing?.approved
                        }
                        onClick={() => setShowReleaseOrderModal(true)}
                      >
                        Liberar Packing
                      </StyledButtonPrimary>
                    )}
                  </div>
                )}
                {user.role === "logistic" && (
                  <div style={{ marginLeft: "auto" }}>
                    {showResumePacking ? (
                      <StyledButtonPrimary
                        style={{ width: 200 }}
                        onClick={() => setShowResumePacking(false)}
                      >
                        Editar Packing
                      </StyledButtonPrimary>
                    ) : (
                      <>
                        <StyledButtonPrimary
                          style={{ width: 200 }}
                          onClick={() => setShowResumePacking(true)}
                        >
                          Visualizar resumo
                        </StyledButtonPrimary>
                      </>
                    )}
                    {!packing?.boarding_instruction_started && (
                      <StyledButtonPrimary
                        type="primary"
                        style={{ width: 200, marginLeft: "10px" }}
                        disabled={
                          isApprovePackginButtonDisabled || !packing?.approved
                        }
                        onClick={() => setShowBoardingInstructionsModal(true)}
                      >
                        Instruir embarque
                      </StyledButtonPrimary>
                    )}
                  </div>
                )}
              </div>
              {showResumePacking ? (
                <>
                  <Divider />
                  {packing?.purchase_orders?.length > 0 && (
                    <PackingResume packing={packing} />
                  )}
                </>
              ) : (
                <>
                  <StyledTabs
                    items={tabs}
                    style={{
                      alignSelf: "center",
                      marginTop: 30,
                      width: "100%",
                    }}
                  />
                  <Divider />
                </>
              )}
            </div>
          </Spin>
        </div>
      </div>
      {packing && user.role === "warehouse" && (
        <CreateSealModal
          isOpen={showCreateSealModal}
          setIsOpen={setShowCreateSealModal}
          packingId={packing.id}
          packingInstance={packing.instance}
          fetchPacking={fetchOrder}
        />
      )}
      {packing && user.role === "sales" && (
        <ReleaseOrderModal
          isOpen={showReleaseOrderModal}
          setIsOpen={setShowReleaseOrderModal}
          packingId={packing.id}
          packingInstance={packing.instance}
          fetchPacking={fetchOrder}
        />
      )}
      {packing && user.role === "logistic" && (
        <BoardInstructionsModal
          isOpen={showBoardingInstructionsModal}
          setIsOpen={setShowBoardingInstructionsModal}
          packingId={packing.id}
          packingInstance={packing.instance}
          fetchPacking={fetchOrder}
        />
      )}
    </>
  );
};

export default PackingPage;
