import "./login.scss";
import { Divider, Checkbox, Spin, Alert } from "antd";
import styled from "styled-components";
import {
  UserOutlined,
  LockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useState, useContext } from "react";
import { message } from "antd";
import { axiosInstance } from "../../services/axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Navbar from "../../components/navbar/navbar";
import { useNavigate } from "react-router-dom";
import { StyledButtonPrimary, StyledInput } from "../../components/custom-ui";
import { StyledTabs } from "../../components/custom-ui";
import Footer from "../../components/footer/footer";
import { UserContext } from "../../contexts/userContext";
import CompalLogo from "../../assets/images/compal_logo.png";

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(56, 158, 13, 1);
    border-color: rgba(56, 158, 13, 1);
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }

  .ant-checkbox-input:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: rgba(56, 158, 13, 1);
  }
`;

const Styledspin = styled(Spin)`
  .ant-spin-dot {
    color: rgba(56, 158, 13, 1);
  }
`;

const Tab1 = ({ submitLogin, isLoading }) => {
  const schema = yup
    .object({
      username: yup.string().required("Usuário é um campo obrigatório"),
      password: yup.string().required("Senha é um campo obrigatório"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data) => submitLogin(data.username, data.password);

  return (
    <>
      <div className="tab__container">
        <Divider style={{ marginTop: 0 }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Styledspin spinning={isLoading}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Controller
                name="username"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <>
                    <StyledInput
                      status={`${errors.username ? "error" : ""}`}
                      style={{ width: 360, height: 40 }}
                      size="large"
                      placeholder={`${
                        errors.username
                          ? errors.username.message
                          : "Nome de usuário"
                      }`}
                      {...field}
                      prefix={
                        errors.username ? (
                          <CloseCircleOutlined />
                        ) : (
                          <UserOutlined
                            style={{ color: "rgba(56, 158, 13, 1)" }}
                          />
                        )
                      }
                      disabled={isLoading}
                    />
                  </>
                )}
              />
              <Controller
                name="password"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <StyledInput
                    status={`${errors.password ? "error" : ""}`}
                    style={{ width: 360, height: 40, marginTop: 28 }}
                    size="large"
                    placeholder={`${
                      errors.password ? errors.password.message : "Senha"
                    }`}
                    {...field}
                    prefix={
                      errors.password ? (
                        <CloseCircleOutlined />
                      ) : (
                        <LockOutlined
                          style={{ color: "rgba(56, 158, 13, 1)" }}
                        />
                      )
                    }
                    type="password"
                    {...field}
                    disabled={isLoading}
                  />
                )}
              />
            </div>
          </Styledspin>
          <div className="tab__container__remember-me">
            <StyledCheckbox onChange={() => undefined} disabled={isLoading}>
              Lembrar de mim
            </StyledCheckbox>
            <StyledButtonPrimary
              className="tab__container__remember-me__forgot-password"
              type="link"
              disabled={isLoading}
            >
              Esqueci a senha
            </StyledButtonPrimary>
          </div>
          <StyledButtonPrimary
            className="tab__container__login-button"
            type="primary"
            size="large"
            style={{ marginTop: 22 }}
            disabled={isLoading || errors.username || errors.password}
            htmlType="submit"
          >
            Entrar
          </StyledButtonPrimary>
        </form>
      </div>
    </>
  );
};

const Tab2 = ({ submitRegister, isLoading }) => {
  const schema = yup
    .object({
      username: yup.string().required("Usuário é um campo obrigatório"),
      password: yup
        .string()
        .required("Senha é um campo obrigatório")
        .min(6, "Senha precisa ter no mínimo 6 caracteres")
        .test(
          "hasUppercase",
          "Senha precisa ter no mínimo uma letra maiúscula",
          (value) => {
            const hasUpperCase = /[A-Z]/.test(value);
            return hasUpperCase;
          }
        ),
      password_confirmation: yup
        .string()
        .required("Confirmação de senha é um campo obrigatório")
        .min(6, "Confirmação de senha precisa ter no mínimo 6 caracteres")
        .test(
          "isEqualPassword",
          "Confirmação de senha precisa ser igual a senha",
          (value) => {
            const hasUpperCase = /[A-Z]/.test(value);
            return hasUpperCase && value === getValues("password");
          }
        ),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data) => submitRegister(data.username, data.password);

  return (
    <>
      <div className="tab__container">
        <Divider style={{ marginTop: 0 }} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Controller
            name="username"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <StyledInput
                status={`${errors.username ? "error" : ""}`}
                style={{ width: 360, height: 40 }}
                size="large"
                placeholder={`${
                  errors.username ? errors.username.message : "Nome de usuário"
                }`}
                {...field}
                prefix={
                  errors.username ? (
                    <CloseCircleOutlined />
                  ) : (
                    <LockOutlined style={{ color: "rgba(56, 158, 13, 1)" }} />
                  )
                }
                disabled={isLoading}
              />
            )}
          />
          <Controller
            name="password"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <StyledInput
                status={`${errors.password ? "error" : ""}`}
                style={{ width: 360, height: 40, marginTop: 16 }}
                size="large"
                placeholder={`${
                  errors.password
                    ? errors.password.message
                    : "Senha (6 dígitos, pelo menos uma maiuscula)"
                }`}
                {...field}
                type="password"
                prefix={
                  errors.password ? (
                    <CloseCircleOutlined />
                  ) : (
                    <LockOutlined style={{ color: "rgba(56, 158, 13, 1)" }} />
                  )
                }
                disabled={isLoading}
              />
            )}
          />
          {errors.password?.type === "min" && (
            <Alert
              message={errors.password.message}
              type="error"
              style={{ marginTop: 10 }}
            />
          )}
          {errors.password?.type === "hasUppercase" && (
            <Alert
              message={errors.password.message}
              type="error"
              style={{ marginTop: 10 }}
            />
          )}
          <Controller
            name="password_confirmation"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <StyledInput
                status={`${errors.password_confirmation ? "error" : ""}`}
                style={{ width: 360, height: 40, marginTop: 16 }}
                size="large"
                placeholder={`${
                  errors.password_confirmation
                    ? errors.password_confirmation.message
                    : "Confirmar senha"
                }`}
                type="password"
                {...field}
                prefix={
                  errors.password_confirmation ? (
                    <CloseCircleOutlined />
                  ) : (
                    <LockOutlined style={{ color: "rgba(56, 158, 13, 1)" }} />
                  )
                }
                disabled={isLoading}
              />
            )}
          />
          {errors.password_confirmation?.type === "min" && (
            <Alert
              message={errors.password_confirmation.message}
              type="error"
              style={{ marginTop: 10 }}
            />
          )}
          {errors.password_confirmation?.type === "isEqualPassword" && (
            <Alert
              message={errors.password_confirmation.message}
              type="error"
              style={{ marginTop: 10 }}
            />
          )}

          <div className="tab__container__actions">
            <StyledButtonPrimary
              className="tab__container__login-button"
              type="primary"
              size="large"
              disabled={
                isLoading ||
                errors.password ||
                errors.password_confirmation ||
                errors.username
              }
              htmlType="submit"
            >
              Cadastrar
            </StyledButtonPrimary>
            <StyledButtonPrimary
              className="tab__container__actions__enter-link"
              type="link"
            >
              Entrar
            </StyledButtonPrimary>
          </div>
        </form>
      </div>
    </>
  );
};

function LoginPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { user, setUser } = useContext(UserContext);
  const submitLogin = async (username, password) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/auth/login", {
        username,
        password,
      });
      localStorage.setItem("token", response.data.access_token);
      axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;
      const responseUser = await axiosInstance.get("/users/me");
      setUser({
        name: responseUser.data.name,
        role: responseUser.data.role.name.toLowerCase(),
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          name: responseUser.data.name,
          role: responseUser.data.role.name.toLowerCase(),
        })
      );
      messageApi.open({
        type: "success",
        content: "Usuário logado com sucesso!",
      });
      navigate(
        responseUser.data.role.name.toLowerCase() === "sales"
          ? "/orders"
          : "/packings"
      );
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Usuário não autorizado",
      });
    } finally {
      setLoading(false);
    }
  };

  const submitRegister = async (username, password) => {
    try {
      setLoading(true);
      await axiosInstance.post("/users", {
        username,
        password,
        name: "Teste",
        role_id: "ff90f046-c999-4edb-a772-b4216e65384c",
      });
      messageApi.open({
        type: "success",
        content: "Usuário logado com sucesso!",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Usuário não autorizado",
      });
    } finally {
      setLoading(false);
    }
  };

  const tabs = [
    {
      label: "Entrar",
      children: <Tab1 submitLogin={submitLogin} isLoading={loading} />,
      key: "1",
    },
  ];

  return (
    <>
      <Navbar />
      {contextHolder}
      <div className="login__container">
        <div className="login__container__subcontainer">
          <div style={{textAlign: "center"}}>
            <img
              src={CompalLogo}
              alt="Logo"
              style={{ width: 200, height: 140 }}
            />
          </div>
          <h1 className="login__container__title">ChatWhiz Logistic</h1>
          <p className="login__container__description">
            Um gestor de processo de outbound logístico.
          </p>
          <div style={{ marginTop: 63 }}>
            <StyledTabs items={tabs} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LoginPage;
