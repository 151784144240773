import styled from "styled-components";
import { Tabs } from "antd";

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      visibility: hidden;
    }
  }
`;

export default StyledTabs;
