import "./orders.scss";
import NavbarVendas from "../../components/navbar/navbarVendas";
import { Divider, Table, Empty, Modal, message, Popconfirm, Spin } from "antd";
import { useState, useRef, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  StyledButtonPrimary,
  StyledInput,
  StyledTextArea,
} from "../../components/custom-ui";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../services/axios";
import { useContext } from "react";
import { DrawerContext } from "../../contexts/drawerContext";
import { useNavigate } from "react-router-dom";

const OrdersPage = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const schema = yup
    .object({
      pndell: yup.string().required("Campo obrigatório"),
      pncea: yup.string().required("Campo obrigatório"),
      quantity: yup
        .number()
        .typeError("Campo obrigatório")
        .required("Campo obrigatório"),
      service: yup.string().required("Campo obrigatório"),
      carrier: yup.string().required("Campo obrigatório"),
      notes: yup.string().optional(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (data) => submitPurchaseOrder(data);

  const purchaseOrderButtonSubmit = useRef();

  const [isRegisterOrderModalOpen, setIsRegisterOrderModalOpen] =
    useState(false);

  const [isPopupConfirmOpen, setIsPopupConfirmOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  const { isVisible } = useContext(DrawerContext);

  const loadSheetInput = useRef(null);

  const columns = [
    {
      title: "PN Dell",
      dataIndex: "pndell",
    },
    {
      title: "PN CEA",
      dataIndex: "pncea",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
    },
    {
      title: "Serviço",
      dataIndex: "service",
    },
    {
      title: "Transportadora",
      dataIndex: "carrier",
    },
    {
      title: "Observação",
      dataIndex: "notes",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (order) => (
        <StyledButtonPrimary
          onClick={() => updateOrder(order)}
          type="link"
          style={{ padding: 0 }}
        >
          Editar
        </StyledButtonPrimary>
      ),
    },
  ];

  useEffect(() => {
    fetchOrders(1);
  }, []);

  useEffect(() => {
    if (!isRegisterOrderModalOpen) {
      setOrder(null);
    }
  }, [isRegisterOrderModalOpen]);

  const fetchOrders = async (pageNumber) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/purchase-order?page=${pageNumber}&pageSize=10`
      );
      const newOrders = response.data.data.map((order) => {
        return {
          key: order.id,
          pndell: order.pn_dell,
          pncea: order.pn_cea,
          quantity: order.quantity,
          service: order.service,
          carrier: order.shipping_company,
          notes: order.observation || "",
          actions: order,
        };
      });
      setOrders(newOrders);
      setTotalItems(response.data.total);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao buscar ordens de compra",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadSheet = () => {
    loadSheetInput?.current.click();
  };

  const handleInputSheetChange = async (event) => {
    const { files } = event.target;
    if (files.length == 1) {
      const formData = new FormData();
      formData.append("purchaseOrdersFile", files[0]);

      try {
        setIsUploading(true);
        await axiosInstance({
          method: "POST",
          headers: { "Content-Type": "multipart/form-data" },
          url: "/purchase-order/upload",
          data: formData,
        });
        fetchOrders(1);
        messageApi.open({
          type: "success",
          content: "Planilha de ordens importada com sucesso!",
        });
      } catch (error) {
        messageApi.open({
          type: "error",
          content: "Error ao importar a planilha ordens de compra",
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const submitPurchaseOrder = async ({
    carrier,
    notes,
    pncea,
    pndell,
    quantity,
    service,
  }) => {
    try {
      setIsLoading(true);
      const payload = {
        pn_dell: pndell,
        pn_cea: pncea,
        quantity,
        shipping_company: carrier,
        service,
        observation: notes,
      };
      await axiosInstance({
        method: order?.id ? "PATCH" : "POST",
        url: `/purchase-order/${order?.id || ""}`,
        data: payload,
      });
      fetchOrders(1);
      messageApi.open({
        type: "success",
        content: `Ordem ${order?.id ? "editada" : "cadastrada"} com sucesso!`,
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: `Error ao ${
          order?.id ? "editar" : "cadastrar"
        } ordem de compra`,
      });
    } finally {
      if (order?.id) {
        setOrder(null);
      }
      setIsRegisterOrderModalOpen(false);
      setIsLoading(false);
    }
  };

  const updateOrder = (order) => {
    reset({
      pndell: order.pn_dell,
      pncea: order.pn_cea,
      quantity: order.quantity,
      service: order.service,
      carrier: order.shipping_company,
      notes: order.observation,
    });
    setOrder(order);
    setIsRegisterOrderModalOpen(true);
  };

  const handleInitPacking = async () => {
    if (selectedOrders.length === 0) {
      messageApi.open({
        type: "warning",
        content: "Selecione ao menos uma ordem de compra",
      });
      return;
    }

    try {
      setIsLoading(true);
      await axiosInstance.post("/packing/start", { orders: selectedOrders });
      messageApi.open({
        type: "success",
        content: "Packing iniciado com sucesso!",
      });
      navigate("/packings");
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao iniciar packing",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedOrders(selectedRowKeys);
    },
  };

  return (
    <>
      <div className="outbound" style={{ marginLeft: isVisible ? 272 : 0 }}>
        <NavbarVendas />
        {contextHolder}
        <div
          className="outbound__body"
          style={{ paddingLeft: isVisible ? 0 : 16 }}
        >
          <div className="outbound__body__card-table">
            <h3 className="outbound__body__card-table__title">
              Ordem de compra
            </h3>
            <Divider />
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className="outbound__body__card-table__subtitle">
                Forescating
              </h4>
              <input
                type="file"
                ref={loadSheetInput}
                onChange={handleInputSheetChange}
                hidden
              />
              <Spin spinning={isUploading}>
                <StyledButtonPrimary
                  size="large"
                  style={{ marginRight: 16 }}
                  onClick={handleLoadSheet}
                  accept=".xlsx, .xsl, .csv, .tsv, .ods"
                  disabled={isUploading}
                >
                  <UploadOutlined />
                  Carregar planilha
                </StyledButtonPrimary>
              </Spin>
              <StyledButtonPrimary
                type="primary"
                size="large"
                onClick={() => {
                  reset({
                    pndell: "",
                    pncea: "",
                    quantity: "",
                    service: "",
                    carrier: "",
                    notes: "",
                  });
                  setIsRegisterOrderModalOpen(true);
                }}
              >
                Cadastrar ordem
              </StyledButtonPrimary>
            </div>
            <div style={{ marginTop: 20 }}>
              <Spin spinning={isLoading}>
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={orders}
                  pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSize: 10,
                    total: totalItems,
                    onChange: (page) => fetchOrders(page),
                  }}
                  scroll={{ y: 700 }}
                  locale={{
                    emptyText: (
                      <Empty description="Não há ordems cadastradas"></Empty>
                    ),
                  }}
                />
              </Spin>
            </div>
            {orders.length > 0 && <Divider />}
            <Popconfirm
              title="Tem certeza que deseja iniciar o packing?"
              description="Uma vez iniciado, apenas alguém da gerencia pode reverter esse processo."
              open={isPopupConfirmOpen}
              onOpenChange={(newOpen) => {
                setIsPopupConfirmOpen(newOpen);
              }}
              onConfirm={handleInitPacking}
              onCancel={() => setIsPopupConfirmOpen(false)}
              okText="Sim, iniciar"
              cancelText="Cancelar"
              okButtonProps={{
                style: { backgroundColor: "#52c41a", borderColor: "#52c41a" },
              }}
            >
              <StyledButtonPrimary
                style={{ marginLeft: "auto" }}
                type="primary"
                size="large"
              >
                Iniciar packing
              </StyledButtonPrimary>
            </Popconfirm>
          </div>
        </div>
      </div>
      {isRegisterOrderModalOpen ? (
        <Modal
          title={`${order?.id ? "Editar" : "Cadastrar"} ordem`}
          open={true}
          onCancel={() => {
            setIsRegisterOrderModalOpen(false);
          }}
          footer={[
            <StyledButtonPrimary
              size="large"
              disabled={isLoading || isSubmiting}
              onClick={() => setIsRegisterOrderModalOpen(false)}
              key={1}
            >
              Cancelar
            </StyledButtonPrimary>,
            <StyledButtonPrimary
              key={2}
              type="primary"
              size="large"
              disabled={isLoading || isSubmiting || !isValid}
              onClick={() => purchaseOrderButtonSubmit?.current?.click()}
            >
              {order?.id ? "Editar" : "Cadastrar"}
            </StyledButtonPrimary>,
          ]}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ marginTop: 16, marginBottom: 30 }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="pndell"
                  style={{ color: "rgba(0, 0, 0, 0.85)" }}
                >
                  PN Dell
                </label>
                <Controller
                  name="pndell"
                  defaultValue={order?.pn_dell}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        style={{ marginTop: 8 }}
                        status={`${errors.pndell ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.pndell ? errors.pndell.message : "PN Dell"
                        }`}
                        {...field}
                        prefix={errors.pndell ? <CloseCircleOutlined /> : null}
                        disabled={isLoading}
                        autoFocus
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ marginLeft: 16, width: "50%" }}>
                <label htmlFor="pncea" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  PN CEA
                </label>
                <Controller
                  name="pncea"
                  defaultValue={order?.pn_cea}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        style={{ marginTop: 8 }}
                        status={`${errors.pncea ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.pncea ? errors.pncea.message : "PN CEA"
                        }`}
                        {...field}
                        prefix={errors.pncea ? <CloseCircleOutlined /> : null}
                        disabled={isLoading}
                        autoFocus
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 24 }}>
              <div style={{ width: "50%" }}>
                <label
                  htmlFor="quantity"
                  style={{ color: "rgba(0, 0, 0, 0.85)" }}
                >
                  Quantidade
                </label>
                <Controller
                  name="quantity"
                  defaultValue={order?.quantity}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        type="number"
                        min={0}
                        style={{ marginTop: 8 }}
                        status={`${errors.quantity ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.quantity ? errors.quantity.message : "Quantity"
                        }`}
                        {...field}
                        prefix={
                          errors.quantity ? <CloseCircleOutlined /> : null
                        }
                        disabled={isLoading}
                        autoFocus
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ marginLeft: 16, width: "50%" }}>
                <label
                  htmlFor="service"
                  style={{ color: "rgba(0, 0, 0, 0.85)" }}
                >
                  Serviço
                </label>
                <Controller
                  name="service"
                  defaultValue={order?.service}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        style={{ marginTop: 8 }}
                        status={`${errors.service ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.service ? errors.service.message : "Service"
                        }`}
                        {...field}
                        prefix={errors.service ? <CloseCircleOutlined /> : null}
                        disabled={isLoading}
                        autoFocus
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <label htmlFor="carrier" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Transportadora
              </label>
              <Controller
                name="carrier"
                defaultValue={order?.shipping_company}
                control={control}
                render={({ field }) => (
                  <>
                    <StyledInput
                      style={{ marginTop: 8 }}
                      status={`${errors.carrier ? "error" : ""}`}
                      size="large"
                      placeholder={`${
                        errors.carrier
                          ? errors.carrier.message
                          : "Transportadora"
                      }`}
                      {...field}
                      prefix={errors.carrier ? <CloseCircleOutlined /> : null}
                      disabled={isLoading}
                      autoFocus
                    />
                  </>
                )}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <label htmlFor="notes" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                Observação
              </label>
              <Controller
                name="notes"
                defaultValue={order?.observation}
                control={control}
                render={({ field }) => (
                  <>
                    <StyledTextArea
                      style={{ marginTop: 8 }}
                      status={`${errors.notes ? "error" : ""}`}
                      size="large"
                      placeholder={`${
                        errors.notes ? errors.notes.message : "Observation"
                      }`}
                      {...field}
                      prefix={errors.notes ? <CloseCircleOutlined /> : null}
                      disabled={isLoading}
                      autoFocus
                    />
                  </>
                )}
              />
            </div>
            <button type="submit" hidden ref={purchaseOrderButtonSubmit} />
          </form>
        </Modal>
      ) : null}
    </>
  );
};

export default OrdersPage;
