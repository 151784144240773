import styled from "styled-components";
import { Tag } from "antd";

const StyledTag = styled(Tag)`
  &.ant-tag {
    min-width: 71px;
    height: 24px;
    text-align: center;
  }
  &.ant-tag-orange {
    color: rgba(250, 173, 20, 1);
    background: rgba(255, 251, 230, 1);
    border-color: rgba(250, 173, 20, 1);
  }
`;

export default StyledTag;
