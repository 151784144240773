import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useState, useRef } from "react";
import {
  StyledButtonPrimary,
  StyledTextArea,
} from "../../../components/custom-ui";
import { axiosInstance } from "../../../services/axios";
import { message, Spin, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const ReleaseOrderModal = ({
  isOpen,
  setIsOpen,
  packingId,
  fetchPacking,
  packingInstance,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const releaseOrderFormSubmit = useRef(null);

  const schema = yup
    .object({
      notification: yup.string().optional(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      notification: "",
    },
  });

  const releaseOrder = async (data) => {
    setIsLoading(true);
    try {
      await axiosInstance
        .post(`/order-release`, { packing_id: packingId })
        .then(async (response) => {
          await axiosInstance.post(
            `/order-release/${response.data.id}/send-notification`,
            {
              description: data.notification,
            }
          );
          messageApi.open({
            type: "success",
            content: "Ordem de pedido criada com sucesso!",
          });
          fetchPacking();
        });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao liberar ordem de compra!",
      });
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Spin spinning={isLoading}>
        <Modal
          title={`Criar ordem de pedido - ${packingInstance}`}
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={[
            <StyledButtonPrimary
              size="large"
              disabled={isLoading || isSubmiting}
              onClick={() => setIsOpen(false)}
              key={1}
            >
              Cancelar
            </StyledButtonPrimary>,
            <StyledButtonPrimary
              key={2}
              type="primary"
              size="large"
              disabled={isLoading || isSubmiting || !isValid}
              onClick={() => releaseOrderFormSubmit?.current?.click()}
            >
              Liberar packing
            </StyledButtonPrimary>,
          ]}
        >
          <div style={{ marginTop: 25, marginBottom: 30 }}>
            <label
              htmlFor="notification"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Notificação(opcional)
            </label>
            <form onSubmit={handleSubmit(releaseOrder)}>
              <Controller
                name="notification"
                control={control}
                render={({ field }) => (
                  <>
                    <StyledTextArea
                      style={{ marginTop: 8 }}
                      status={`${errors.notification ? "error" : ""}`}
                      size="large"
                      placeholder={`${
                        errors.notification ? errors.notification.message : ""
                      }`}
                      {...field}
                      prefix={
                        errors.notification ? <CloseCircleOutlined /> : null
                      }
                      disabled={isLoading}
                      autoFocus
                      rows={4}
                    />
                  </>
                )}
              />
              <button hidden type="submit" ref={releaseOrderFormSubmit} />
            </form>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default ReleaseOrderModal;
