import { createContext, useState } from "react";

const userPersisted = JSON.parse(localStorage.getItem('user'));

const userContextInitalValue = {
  user: userPersisted ? userPersisted : {}
};

const UserContext = createContext(userContextInitalValue);

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(userContextInitalValue.user);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider, userContextInitalValue };
