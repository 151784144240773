import "./navbarVendas.scss";
import { Badge, Avatar } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import DefaultAvatar from "../../assets/images/default-avatar.png";
import { DrawerContext } from "../../contexts/drawerContext";
import { useContext } from "react";
import Drawer from "../drawer/drawer";
import { SendOutlined } from "@ant-design/icons";
import { StyledTag } from "../custom-ui";
import { useNavigate } from "react-router-dom";
import { isRouteActive } from "./../../services/navbar.service";
import { UserContext } from "../../contexts/userContext";

const NavbarVendas = () => {
  const navigate = useNavigate();
  const { isVisible, setIsVisible } = useContext(DrawerContext);

  const { user } = useContext(UserContext);

  const outboundItems = [
    {
      key: "1",
      label: (
        <div className="drawer__menu__item">
          <SendOutlined className="drawer__menu__item__icon" />
          Outbound
        </div>
      ),
      children: (
        <div className="drawer__menu__item__subitems">
          {(user.role === "sales" || user.role === "admin") && (
            <div
              className={`drawer__menu__item__subitems__subitem ${
                isRouteActive("orders") ? "active" : ""
              }`}
              onClick={() => navigate("/orders")}
            >
              <p>Ordens de compras</p>
            </div>
          )}
          <div
            className={`drawer__menu__item__subitems__subitem ${
              isRouteActive("packings") ? "active" : ""
            }`}
            onClick={() => navigate("/packings")}
          >
            <p>Packings registrados</p>
          </div>
        </div>
      ),
    },
  ];

  const configTag = {
    sales: {
      label: "VENDAS",
      color: "orange",
    },
    logistic: {
      label: "LOGÍSTICA",
      color: "blue",
    },
    warehouse: {
      label: "WAREHOUSE",
      color: "red",
    },
    admin: {
      label: "ADMINISTRADOR",
      color: "blue",
    },
  };

  return (
    <>
      <Drawer items={outboundItems} isDrawerVisible={isVisible} />
      <nav className="navbar-vendas">
        <div className="navbar-vendas__card">
          {isVisible ? (
            <MenuFoldOutlined onClick={() => setIsVisible(false)} />
          ) : (
            <MenuUnfoldOutlined onClick={() => setIsVisible(true)} />
          )}
          <div className="navbar-vendas__card__right-nav">
            <StyledTag color={configTag[user.role]?.color}>
              {configTag[user.role]?.label}
            </StyledTag>
            <SearchOutlined style={{ marginLeft: 25, marginRight: 25 }} />
            <QuestionCircleOutlined style={{ marginRight: 25 }} />
            <div style={{ marginRight: 30 }}>
              <Badge count={11} size="small" offset={[9.5, 0]}>
                <BellOutlined style={{ fontSize: 16 }} />
              </Badge>
            </div>
            <Avatar
              src={
                <img
                  src={DefaultAvatar}
                  alt="avatar"
                  style={{ width: 24, height: 24 }}
                />
              }
              style={{ marginRight: 8 }}
            />
            <p className="navbar-vendas__card__right-nav__name">{user.name}</p>
            {/* <TranslationOutlined style={{ fontSize: 18 }} /> */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarVendas;
