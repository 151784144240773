import { GlobalOutlined } from "@ant-design/icons";
import "./navbar.scss";


const Navbar = () => {
  return (
    <div>
      <GlobalOutlined
        style={{
          color: "rgba(0, 0, 0, 0.45)",
          float: "right",
          paddingRight: 25,
          marginTop: 15,
        }}
      />
    </div>
  );
};

export default Navbar;
