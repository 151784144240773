import styled from "styled-components";
import { Input } from "antd";

const StyledInput = styled(Input)`
  &.ant-input-outlined:focus,
  &.ant-input-outlined:focus-within,
  &.ant-input-outlined:hover {
    border-color: rgba(56, 158, 13, 1);
  }
`;

export default StyledInput;
