import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useState, useRef } from "react";
import {
  StyledButtonPrimary,
  StyledInput,
} from "../../../components/custom-ui";
import { axiosInstance } from "../../../services/axios";
import { message, Spin, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const CreateSealModal = ({
  isOpen,
  setIsOpen,
  packingId,
  fetchPacking,
  packingInstance,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  const createSealFormSubmit = useRef(null);

  const schema = yup
    .object({
      seal: yup.string().required("Campo obrigatório"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      seal: "",
    },
  });

  const approvePacking = async (data) => {
    setIsLoading(true);
    try {
      await axiosInstance
        .post(`/packing/approve/${packingId}`)
        .then(async () => {
          messageApi.open({
            type: "success",
            content: "Packing aprovado com sucesso!",
          });
          await axiosInstance.patch(`/packing/${packingId}`, {
            seal: data.seal,
          });
          messageApi.open({
            type: "success",
            content: "Lacre criado com sucesso!",
          });
          fetchPacking();
        });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao criar lacre e aprovar packing",
      });
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Spin spinning={isLoading}>
        <Modal
          title={`Criar o lacre do packing - ${packingInstance}`}
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={[
            <StyledButtonPrimary
              size="large"
              disabled={isLoading || isSubmiting}
              onClick={() => setIsOpen(false)}
              key={1}
            >
              Cancelar
            </StyledButtonPrimary>,
            <StyledButtonPrimary
              key={2}
              type="primary"
              size="large"
              disabled={isLoading || isSubmiting || !isValid}
              onClick={() => createSealFormSubmit?.current?.click()}
            >
              Aprovar
            </StyledButtonPrimary>,
          ]}
        >
          <div style={{ width: "50%" }}>
            <label htmlFor="seal" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              Lacre
            </label>
            <form onSubmit={handleSubmit(approvePacking)}>
              <Controller
                name="seal"
                control={control}
                render={({ field }) => (
                  <>
                    <StyledInput
                      style={{ marginTop: 8 }}
                      status={`${errors.seal ? "error" : ""}`}
                      size="large"
                      placeholder={`${errors.seal ? errors.seal.message : ""}`}
                      {...field}
                      prefix={errors.seal ? <CloseCircleOutlined /> : null}
                      disabled={isLoading}
                      autoFocus
                    />
                  </>
                )}
              />
              <button hidden type="submit" ref={createSealFormSubmit} />
            </form>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default CreateSealModal;
