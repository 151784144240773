import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  StyledButtonPrimary,
  StyledInput,
} from "../../../components/custom-ui";
import { CloseCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../services/axios";
import { message } from "antd";

const SalesForm = ({ disabled, packingId, order, fetchOrder }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const schema = yup
    .object({
      family: yup.string().required("Campo obrigatório"),
      pn: yup.string().required("Campo obrigatório"),
      quantity: yup.number().required("Campo obrigatório"),
      service_time: yup.string().required("Campo obrigatório"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      family: order.family,
      pn: order.pn,
      quantity: order.plate_quantity,
      service_time: order.service_time,
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await axiosInstance.patch(
        `/packing/${packingId}/purchase-order/${order.id}`,
        {
          family: data.family,
          pn: data.pn,
          plate_quantity: data.quantity,
          service_time: data.service_time,
        }
      );
      messageApi.open({
        type: "success",
        content: "Ordem de compra atualizada com sucesso!",
      });
      fetchOrder();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao salvar dados de packing de vendas",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    reset({
      family: "",
      pn: "",
      quantity: "",
      service_time: "",
    });
  };

  return (
    <div className="packing__form">
      {contextHolder}
      <h5 className="packing__form__title">Vendas</h5>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 24 }}>
        <div style={{ display: "flex", gap: 24 }}>
          <div style={{ width: "50%" }}>
            <label htmlFor="family" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              Família
            </label>
            <Controller
              name="family"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.family ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.family ? errors.family.message : ""
                    }`}
                    {...field}
                    prefix={errors.family ? <CloseCircleOutlined /> : null}
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label htmlFor="pn" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              PN
            </label>
            <Controller
              name="pn"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.pn ? "error" : ""}`}
                    size="large"
                    placeholder={`${errors.pn ? errors.pn.message : ""}`}
                    {...field}
                    prefix={errors.pn ? <CloseCircleOutlined /> : null}
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 24, marginTop: 30 }}>
          <div style={{ width: "50%" }}>
            <label htmlFor="quantity" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              Qtd de placas a serem transportadas
            </label>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="number"
                    style={{ marginTop: 8 }}
                    status={`${errors.quantity ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.quantity ? errors.quantity.message : ""
                    }`}
                    {...field}
                    prefix={errors.quantity ? <CloseCircleOutlined /> : null}
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label
              htmlFor="service_time"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Tempo de serviço
            </label>
            <Controller
              name="service_time"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.service_time ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.service_time ? errors.service_time.message : ""
                    }`}
                    {...field}
                    prefix={
                      errors.service_time ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ margin: "30px auto", float: "right" }}>
          <StyledButtonPrimary
            size="large"
            disabled={isLoading || disabled}
            style={{ marginRight: 20 }}
            onClick={() => resetForm()}
          >
            Limpar
          </StyledButtonPrimary>
          <StyledButtonPrimary
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading || isSubmiting || disabled}
          >
            Salvar
          </StyledButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default SalesForm;
