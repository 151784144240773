import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  StyledButtonPrimary,
  StyledInput,
} from "../../../components/custom-ui";
import { CloseCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../services/axios";

const LogisticForm = ({ disabled, packingId, order, fetchOrder }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);
  const schema = yup
    .object({
      reference: yup.string().required("Campo obrigatório"),
      cubed_weight: yup.number().required("Campo obrigatório"),
      dimensional_weight: yup.number().required("Campo obrigatório"),
      shipping_company: yup.string().required("Campo obrigatório"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reference: order.reference,
      cubed_weight: order.cubed_weight,
      dimensional_weight: order.dimensional_weight,
      shipping_company: order.shipping_company,
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await axiosInstance.patch(
        `/packing/${packingId}/purchase-order/${order.id}`,
        {
          reference: data.reference,
          cubed_weight: data.cubed_weight,
          dimensional_weight: data.dimensional_weight,
          shipping_company: data.shipping_company,
        }
      );
      messageApi.open({
        type: "success",
        content: "Ordem de compra atualizada com sucesso!",
      });
      fetchOrder();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao salvar dados de packing de logística",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    reset({
      reference: "",
      cubed_weight: "",
      dimensional_weight: "",
      shipping_company: "",
    });
  };

  return (
    <div className="packing__form">
      {contextHolder}
      <h5 className="packing__form__title">Logística</h5>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 24 }}>
        <div style={{ display: "flex", gap: 24 }}>
          <div style={{ width: "50%" }}>
            <label htmlFor="reference" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              Referência
            </label>
            <Controller
              name="reference"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.reference ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.reference ? errors.reference.message : ""
                    }`}
                    {...field}
                    prefix={errors.reference ? <CloseCircleOutlined /> : null}
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label
              htmlFor="cubed_weight"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Peso Cubado
            </label>
            <Controller
              name="cubed_weight"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="number"
                    style={{ marginTop: 8 }}
                    status={`${errors.cubed_weight ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.cubed_weight ? errors.cubed_weight.message : ""
                    }`}
                    {...field}
                    prefix={
                      errors.cubed_weight ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 24, marginTop: 30 }}>
          <div style={{ width: "50%" }}>
            <label
              htmlFor="dimensional_weight"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Peso Dimensional
            </label>
            <Controller
              name="dimensional_weight"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="number"
                    style={{ marginTop: 8 }}
                    status={`${errors.dimensional_weight ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.dimensional_weight
                        ? errors.dimensional_weight.message
                        : ""
                    }`}
                    {...field}
                    prefix={
                      errors.dimensional_weight ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "50%" }}>
            <label
              htmlFor="shipping_company"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Transportadora
            </label>
            <Controller
              name="shipping_company"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.shipping_company ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.shipping_company
                        ? errors.shipping_company.message
                        : ""
                    }`}
                    {...field}
                    prefix={
                      errors.shipping_company ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ margin: "30px auto", float: "right" }}>
          <StyledButtonPrimary
            size="large"
            disabled={isLoading || disabled}
            style={{ marginRight: 20 }}
            onClick={() => resetForm()}
          >
            Limpar
          </StyledButtonPrimary>
          <StyledButtonPrimary
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading || isSubmiting || !isValid || disabled}
          >
            Salvar
          </StyledButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default LogisticForm;
