import "./packings.scss";
import NavbarVendas from "../../components/navbar/navbarVendas";
import { Divider, Table, Empty, Spin, message, Badge } from "antd";
import { useState, useEffect } from "react";
import { StyledButtonPrimary, StyledInput } from "../../components/custom-ui";
import { axiosInstance } from "../../services/axios";
import { useContext } from "react";
import { DrawerContext } from "../../contexts/drawerContext";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";

const PackingsPage = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [packings, setPackings] = useState([]);
  const [totalItems, setTotalItems] = useState([]);

  const { user } = useContext(UserContext);

  const { isVisible } = useContext(DrawerContext);

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: "Instância",
      dataIndex: "instance",
    },
    {
      title: "Qtd de PN",
      dataIndex: "pn_quantity",
    },
    {
      title: "Qtd itens",
      dataIndex: "itens_quantity",
    },
    {
      title: "Atendida",
      dataIndex: "served",
    },
    {
      title: "Lacre",
      dataIndex: "seal",
    },
    {
      title: "Data",
      dataIndex: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (packing) => (
        <div>
          {packing.approved ? (
            <>
              <Badge color={"green"} text={"Aprovado por warehouse"} />
              {user.role === "sales" && (
                <div style={{ marginTop: 10 }}>
                  {packing.order_release_started ? (
                    <Badge color={"green"} text={"Ordem de pedido liberada"} />
                  ) : (
                    <Badge color={"orange"} text={"Aguardando liberação"} />
                  )}
                </div>
              )}
              {user.role === "logistic" && (
                <div style={{ marginTop: 10 }}>
                  {packing.boarding_instruction_started ? (
                    <Badge
                      color={"green"}
                      text={"Instrução de embarque enviada"}
                    />
                  ) : (
                    <Badge
                      color={"orange"}
                      text={"Aguardando instrução de embarque"}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <Badge
              color={"orange"}
              text={"Aguardando aprovação de warehouse"}
            />
          )}
        </div>
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (packingId) => (
        <StyledButtonPrimary
          type="link"
          onClick={() => navigate(`/packings/${packingId}`)}
        >
          Ver
        </StyledButtonPrimary>
      ),
    },
  ];

  const fetchOrders = async ({ pageNumber = 1, search = "" } = {}) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/packing?page=${pageNumber}&pageSize=10&instance=${search}`
      );
      const newPackings = response.data.data.map((packing) => {
        return {
          key: packing.id,
          instance: packing.instance,
          pn_quantity: packing.pn_quantity,
          itens_quantity: packing.items_quantity,
          served: packing.served,
          seal: packing.seal,
          date: format(new Date(packing.created_at), "dd/MM/yyyy"),
          status: packing,
          actions: packing.id,
        };
      });
      setPackings(newPackings);
      setTotalItems(response.data.total);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao buscar packings",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const searchPackings = (value) => {
    fetchOrders({ search: value });
  };

  return (
    <>
      <div className="packings" style={{ marginLeft: isVisible ? 272 : 0 }}>
        <NavbarVendas />
        {contextHolder}
        <div
          className="packings__body"
          style={{ paddingLeft: isVisible ? 0 : 16 }}
        >
          <div className="packings__body__card-table">
            <h3 className="packings__body__card-table__title">
              Packings registrados
            </h3>
            <Divider />
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className="outbound__body__card-table__subtitle">
                Lista de Packings
              </h4>
              <StyledInput.Search
                style={{ width: "50%" }}
                loading={isLoading}
                onSearch={searchPackings}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Spin spinning={isLoading}>
                <Table
                  columns={columns}
                  dataSource={packings}
                  pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSize: 10,
                    total: totalItems,
                    onChange: (page) => fetchOrders(page),
                  }}
                  scroll={{ y: 700 }}
                  locale={{
                    emptyText: (
                      <Empty description="Não há ordems cadastradas"></Empty>
                    ),
                  }}
                />
              </Spin>
            </div>
            {packings.length > 0 && <Divider />}
          </div>
        </div>
      </div>
    </>
  );
};

export default PackingsPage;
