import { setDefaultOptions } from "date-fns";

import { ptBR as dateFnsPtBR } from "date-fns/locale";

const formatDistanceLocale = {
  lessThanXSeconds: {
    singular: "{{count}} segundo",
    plural: "{{count}} segundos",
  },
  xSeconds: {
    singular: "{{count}} segundo",
    plural: "{{count}} segundos",
  },
  halfAMinute: "30 segundos",
  lessThanXMinutes: {
    singular: "{{count}} minuto",
    plural: "{{count}} minutos",
  },
  xMinutes: {
    singular: "{{count}} minuto",
    plural: "{{count}} minutos",
  },
  aboutXHours: {
    singular: "{{count}} hora",
    plural: "{{count}} horas",
  },
  xHours: {
    singular: "{{count}} hora",
    plural: "{{count}} horas",
  },
  xDays: {
    singular: "{{count}} dia",
    plural: "{{count}} dias",
  },
  aboutXWeeks: {
    singular: "{{count}} semana",
    plural: "{{count}} semanas",
  },
  xWeeks: {
    singular: "{{count}} semana",
    plural: "{{count}} semanas",
  },
  aboutXMonths: {
    singular: "{{count}} mês",
    plural: "{{count}} meses",
  },
  xMonths: {
    singular: "{{count}} mês",
    plural: "{{count}} meses",
  },
  aboutXYears: {
    singular: "{{count}} ano",
    plural: "{{count}} anos",
  },
  xYears: {
    singular: "{{count}} ano",
    plural: "{{count}} anos",
  },
  overXYears: {
    singular: "{{count}} ano",
    plural: "{{count}} anos",
  },
  almostXYears: {
    singular: "{{count}} ano",
    plural: "{{count}} anos",
  },
};

function formatDistance(token, count, options) {
  options = options || {};

  const wordFlexion = count > 1 ? "plural" : "singular";

  const result = formatDistanceLocale[token][wordFlexion].replace("{{count}}", count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "há " + result;
    } else {
      return result + " atrás.";
    }
  }

  return result;
}

setDefaultOptions({ locale: { ...dateFnsPtBR, formatDistance } });
