import "./packing-resume.scss";
import OrderResumeCard from "./order-resume-card";
import { Divider, Pagination } from "antd";

const PackingResume = ({ packing }) => {
  return (
    <div className="packing-resume">
      {packing?.purchase_orders.map((order) => (
        <>
          <OrderResumeCard order={order} />
          <Divider />
        </>
      ))}
      <Pagination
        defaultCurrent={1}
        total={packing.purchase_orders.length}
        style={{ alignSelf: "center" }}
      />
    </div>
  );
};

export default PackingResume;
