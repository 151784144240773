import { createContext, useState } from "react";

const drawerContextInitialValue = {
  isVisible: true,
  setIsVisible: () => {},
};

const DrawerContext = createContext(drawerContextInitialValue);

const DrawerContextProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <DrawerContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerContextProvider, drawerContextInitialValue };
