import { useState } from "react";
import { StyledButtonPrimary } from "../../../components/custom-ui";
import { message, Spin, Modal } from "antd";
import Editor from "react-simple-wysiwyg";
import { axiosInstance } from "../../../services/axios";

const BoardInstructionsModal = ({
  isOpen,
  setIsOpen,
  packingId,
  fetchPacking,
  packingInstance,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [html, setHtml] = useState("Minha <b>instrução de embarque</b>");

  const sendBoardingInstruction = async (html) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("boarding-instruction", {
        email_template_id: "006ad65a-5a41-40b7-b4ad-59b31c631e8f",
        packing_id: packingId,
      });
      console.log('response', response);
      await axiosInstance.post(
        `/boarding-instruction/${response.data.id}/send`,
        {
          email_html: html,
          destination: "SP",
        }
      );
      messageApi.open({
        type: "success",
        content: "Instrução de embarque enviada com sucesso!",
      });
      fetchPacking();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao enviar instruções de embarque!",
      });
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Spin spinning={isLoading}>
        <Modal
          title={`Criar instrução de embarque - ${packingInstance}`}
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          footer={[
            <StyledButtonPrimary
              size="large"
              disabled={isLoading}
              onClick={() => setIsOpen(false)}
              key={1}
            >
              Cancelar
            </StyledButtonPrimary>,
            <StyledButtonPrimary
              key={2}
              type="primary"
              size="large"
              disabled={isLoading}
              onClick={() => sendBoardingInstruction(html)}
            >
              Enviar
            </StyledButtonPrimary>,
          ]}
        >
          <div style={{ marginTop: 25, marginBottom: 30 }}>
            <label htmlFor="email" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              E-mail:
            </label>
            <div style={{ marginTop: 10 }}>
              <Editor value={html} onChange={(e) => setHtml(e.target.value)} />
            </div>
          </div>
        </Modal>
      </Spin>
    </>
  );
};

export default BoardInstructionsModal;
