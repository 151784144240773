import { Divider } from "antd";

const OrderResumeCard = ({ order }) => {
  return (
    <div className="packing-resume__card">
      <div className="packing-resume__card__label">
        <p style={{ transform: "rotate(-90deg)" }}>{order.pn}</p>
        <p style={{ transform: "rotate(-90deg)" }}>PN</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: 152,
        }}
      >
        <h3 style={{ marginBottom: 2 }}>Família</h3>
        <h4 className="text-primary" style={{ wordWrap: "break-word" }}>
          {order.family}
        </h4>

        <h3 style={{ marginTop: 20 }}>PN</h3>
        <h4 className="text-primary">{order.pn}</h4>

        <h3 style={{ marginTop: 20 }}>Qtd de placas</h3>
        <h4 className="text-primary">{order.plate_quantity}</h4>

        <h3 style={{ marginTop: 20 }}>Tempo de serviço</h3>
        <h4 className="text-primary">{order.service_time}</h4>
      </div>
      <Divider type="vertical" style={{ height: 500, margin: "0 40px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: 152,
        }}
      >
        <h3>Referência</h3>
        <h4 className="text-primary" style={{ wordWrap: "break-word" }}>
          {order.reference}
        </h4>

        <h3 style={{ marginTop: 20 }}>Peso Dimensional</h3>
        <h4 className="text-primary">{order.dimensional_weight}</h4>

        <h3 style={{ marginTop: 20 }}>Peso Cubado</h3>
        <h4 className="text-primary">{order.cubed_weight}</h4>

        <h3 style={{ marginTop: 20 }}>Transportadora</h3>
        <h4 className="text-primary">{order.shipping_company}</h4>
      </div>
      <Divider type="vertical" style={{ height: 500, margin: "0 40px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: 152,
        }}
      >
        <h3>Dimensão Pallet</h3>
        <h4 className="text-primary" style={{ wordWrap: "break-word" }}>
          {order.pallet_dimension_x}x{order.pallet_dimension_y}x
          {order.pallet_dimension_z}
        </h4>

        <h3 style={{ marginTop: 20 }}>Qtd Caixas</h3>
        <h4 className="text-primary">{order.box_quantity}</h4>

        <h3 style={{ marginTop: 20 }}>Dimensão Caixa</h3>
        <h4 className="text-primary">
          {order.box_dimension_x}x{order.box_dimension_y}x
          {order.box_dimension_z}mm
        </h4>

        <h3 style={{ marginTop: 20 }}>PN Caixa</h3>
        <h4 className="text-primary">{order.pn_box}</h4>
      </div>
      <Divider type="vertical" style={{ height: 500, margin: "0 40px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 152,
        }}
      >
        <h3>Peso Bruto</h3>
        <h4 className="text-primary" style={{ wordWrap: "break-word" }}>
          {order.gross_weight}
        </h4>

        <h3 style={{ marginTop: 20 }}>Qtd de Volume/Pallets</h3>
        <h4 className="text-primary">{order.pallet_quantity}</h4>
      </div>
    </div>
  );
};

export default OrderResumeCard;
