import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem("token") || "";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "*/*",
    "Authorization": `Bearer ${token}`
  },
  withCredentials: false
});

export { axiosInstance };
