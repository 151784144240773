import { Button } from "antd";
import styled from "styled-components";

const StyledButtonPrimary = styled(Button)`
  &.ant-btn {
    border: 1px solid rgba(56, 158, 13, 1);
  }

  &.ant-btn-primary,
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: white !important;
  }

  &.ant-btn-primary:disabled {
    border-color: #d9d9d9;
    background: rgba(245, 245, 245, 1);
    color: rgba(0, 0, 0, 0.25);
  }

  &.ant-btn-link {
    border: none;
    color: rgba(56, 158, 13, 1);
    &:hover {
      color: rgba(56, 158, 13, 0.85) !important;
    }
  }
`;

export default StyledButtonPrimary;
