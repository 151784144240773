import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
  StyledButtonPrimary,
  StyledInput,
} from "../../../components/custom-ui";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import { axiosInstance } from "../../../services/axios";

const WareHouseForm = ({ disabled, packingId, order, fetchOrder }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup
    .object({
      dimension_box_x: yup.number().required("Campo obrigatório"),
      dimension_box_y: yup.number().required("Campo obrigatório"),
      dimension_box_z: yup.number().required("Campo obrigatório"),
      dimension_pallet_x: yup.number().required("Campo obrigatório"),
      dimension_pallet_y: yup.number().required("Campo obrigatório"),
      dimension_pallet_z: yup.number().required("Campo obrigatório"),
      gross_weight: yup.number().required("Campo obrigatório"),
      boxes_quantity: yup.number().required("Campo obrigatório"),
      pallets_quantity: yup.number().required("Campo obrigatório"),
      pn_box: yup.string().required("Campo obrigatório"),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmiting },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dimension_box_x: order.box_dimension_x,
      dimension_box_y: order.box_dimension_y,
      dimension_box_z: order.box_dimension_z,
      dimension_pallet_x: order.pallet_dimension_x,
      dimension_pallet_y: order.pallet_dimension_y,
      dimension_pallet_z: order.pallet_dimension_z,
      gross_weight: order.gross_weight,
      boxes_quantity: order.box_quantity,
      pn_box: order.pn_box,
      pallets_quantity: order.pallet_quantity,
    },
  });

  const resetForm = () => {
    reset({
      dimension_box_x: "",
      dimension_box_y: "",
      dimension_box_z: "",
      dimension_pallet_x: "",
      dimension_pallet_y: "",
      dimension_pallet_z: "",
      gross_weight: "",
      boxes_quantity: "",
      pn_box: "",
      pallets_quantity: "",
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await axiosInstance.patch(
        `/packing/${packingId}/purchase-order/${order.id}`,
        {
          box_dimension_x: data.dimension_box_x,
          box_dimension_y: data.dimension_box_y,
          box_dimension_z: data.dimension_box_z,
          pallet_dimension_x: data.dimension_pallet_x,
          pallet_dimension_y: data.dimension_pallet_y,
          pallet_dimension_z: data.dimension_pallet_z,
          gross_weight: data.gross_weight,
          box_quantity: data.boxes_quantity,
          pn_box: data.pn_box,
          pallet_quantity: data.pallets_quantity,
        }
      );
      messageApi.open({
        type: "success",
        content: "Ordem de compra atualizada com sucesso!",
      });
      fetchOrder();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error ao salvar dados de packing de warehousse",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="packing__form">
      {contextHolder}
      <h5 className="packing__form__title">Warehouse</h5>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 24 }}>
        <div style={{ display: "flex", gap: 70 }}>
          <div style={{ width: "33%" }}>
            <label
              htmlFor="dimension_box_x"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Dimensão CX (mm)
            </label>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ width: "33%" }}>
                <Controller
                  name="dimension_box_x"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        type="number"
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_box_x ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_box_x
                            ? errors.dimension_box_x.message
                            : "X"
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_box_x ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
              <CloseOutlined style={{ paddingTop: 10 }} />
              <div style={{ width: "33%" }}>
                <Controller
                  type="number"
                  name="dimension_box_y"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_box_y ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_box_y
                            ? errors.dimension_box_y.message
                            : "Y"
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_box_y ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
              <CloseOutlined style={{ paddingTop: 10 }} />
              <div style={{ width: "33%" }}>
                <Controller
                  type="number"
                  name="dimension_box_z"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_box_z ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_box_z
                            ? errors.dimension_box_z.message
                            : "Z"
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_box_z ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "33%" }}>
            <label
              htmlFor="dimension_pallet_x"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Dimensão Pallet (cm)
            </label>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <div style={{ width: "33%" }}>
                <Controller
                  name="dimension_pallet_x"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        type="number"
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_pallet_x ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_pallet_x
                            ? errors.dimension_pallet_x.message
                            : ""
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_pallet_x ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
              <CloseOutlined style={{ paddingTop: 10 }} />
              <div style={{ width: "33%" }}>
                <Controller
                  name="dimension_pallet_y"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        type="number"
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_pallet_y ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_pallet_y
                            ? errors.dimension_pallet_y.message
                            : ""
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_pallet_y ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
              <CloseOutlined style={{ paddingTop: 10 }} />
              <div style={{ width: "33%" }}>
                <Controller
                  name="dimension_pallet_z"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledInput
                        type="number"
                        style={{ marginTop: 8 }}
                        status={`${errors.dimension_pallet_z ? "error" : ""}`}
                        size="large"
                        placeholder={`${
                          errors.dimension_pallet_z
                            ? errors.dimension_pallet_z.message
                            : ""
                        }`}
                        {...field}
                        prefix={
                          errors.dimension_pallet_z ? (
                            <CloseCircleOutlined />
                          ) : null
                        }
                        disabled={isLoading || disabled}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div style={{ width: "20%" }}>
            <label
              htmlFor="gross_weight"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Peso Bruto (kg)
            </label>
            <Controller
              name="gross_weight"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="number"
                    style={{ marginTop: 8 }}
                    status={`${errors.gross_weight ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.gross_weight ? errors.gross_weight.message : ""
                    }`}
                    {...field}
                    prefix={
                      errors.gross_weight ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 24, marginTop: 30 }}>
          <div style={{ width: "33%" }}>
            <label
              htmlFor="boxes_quantity"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Quantidade de caixas
            </label>
            <Controller
              name="boxes_quantity"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.boxes_quantity ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.boxes_quantity ? errors.boxes_quantity.message : ""
                    }`}
                    {...field}
                    prefix={
                      errors.boxes_quantity ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "33%" }}>
            <label
              htmlFor="pallets_quantity"
              style={{ color: "rgba(0, 0, 0, 0.85)" }}
            >
              Quantidade de Volume/Pallets
            </label>
            <Controller
              name="pallets_quantity"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.pallets_quantity ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.pallets_quantity
                        ? errors.pallets_quantity.message
                        : ""
                    }`}
                    {...field}
                    prefix={
                      errors.pallets_quantity ? <CloseCircleOutlined /> : null
                    }
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
          <div style={{ width: "33%" }}>
            <label htmlFor="pn_box" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
              PN Caixa
            </label>
            <Controller
              name="pn_box"
              control={control}
              render={({ field }) => (
                <>
                  <StyledInput
                    style={{ marginTop: 8 }}
                    status={`${errors.pn_box ? "error" : ""}`}
                    size="large"
                    placeholder={`${
                      errors.pn_box ? errors.pn_box.message : ""
                    }`}
                    {...field}
                    prefix={errors.pn_box ? <CloseCircleOutlined /> : null}
                    disabled={isLoading || disabled}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div style={{ margin: "30px auto", float: "right" }}>
          <StyledButtonPrimary
            size="large"
            disabled={isLoading || disabled}
            style={{ marginRight: 20 }}
            onClick={() => resetForm()}
          >
            Limpar
          </StyledButtonPrimary>
          <StyledButtonPrimary
            type="primary"
            size="large"
            htmlType="submit"
            disabled={isLoading || isSubmiting || disabled}
          >
            Salvar
          </StyledButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default WareHouseForm;
