import { GlobalOutlined } from "@ant-design/icons";
import "./footer.scss";

const Footer = () => {


  return (
    <div style={{ paddingBottom: 30, marginTop: "auto" }}>
      <div className="footer">
        <p className="footer__company-name">Compal</p>
        <GlobalOutlined
          style={{
            color: "rgba(0, 0, 0, 0.45)",
            marginRight: 27,
            marginLeft: 30,
          }}
        />
        <p className="footer__company-name">FabWork</p>
      </div>
      <p
        style={{
          marginTop: 10,
          textAlign: "center",
          color: "rgba(0, 0, 0, 0.25)",
        }}
      >
        Copyright ©2024 ChatWhiz Logistic Powered by Fabwork.
      </p>
    </div>
  );
};

export default Footer;
